import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import BannerSolutions from '../components/BannerSolutions';

import unifiedItPicture from '../assets/images/solutions/unifiedIt.jpg';
import voiceAndDataPicture from '../assets/images/solutions/voiceAndData.jpg';
import projectManagementPicture from '../assets/images/solutions/projectManagement.jpg';
import phoneSystemsPicture from '../assets/images/solutions/phoneSystems.jpeg';
import erpPicture from '../assets/images/solutions/erp.jpg';
import pointOfSalePicture from '../assets/images/solutions/pointOfSale.jpg';

const Solutions = () => (
  <Layout>
    <Helmet>
      <title>Solutions</title>
      <meta name="description" content="We offer and support VOIP and standard PBX phone systems for all types of businesses. Contact us today for a free quote." />
    </Helmet>

    <BannerSolutions />

    <div id="main">
      <section id="one" className="spotlights">
        <section>
          <img
            src={unifiedItPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Unified IT</h3>
              </header>
              <p>
Let us manage your entire technology infrastructure through our simple
                {' '}
                <strong>Unified IT</strong>
                {' '}
program. This program allows your business to rum smoothly and hassle free while all of your computer, network, telephony and data services are supported and maintaned by our team of experts. With our turnkey solutions your maintenance costs will be drastically reduced as there is only one company to contact for all your needs.
                <br />
                <br />
We offer various packages to maintain and monitor the equipment we install, and give excellent incentives to go unified.

              </p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={voiceAndDataPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Voice / Data</h3>
              </header>
              <p>
Voice and Data providers have made a business out of overcharging customers for their services. Ryecliff is partnered with over 60 of the largests voice/data providers in the nation and we can get you the services you need at the prices you want.
                <br />
                <br />
Our long term relationships with these providers assures our customers receieve the best rates available with the support they need for their thriving businesses.

              </p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={projectManagementPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Project Management</h3>
              </header>
              <p>
Our team of experts can help your business implement and manage any type of IT project, from a major infrastructure upgrade, to a server migration, we have the expertise you need to complete the job.
                <br />
                <br />
Businesses always need things done yesterday, at Ryecliff we pride ourselves in making sure all of our projects get done on time and within budget. Call us today to setup a gameplan for your next project.

              </p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={phoneSystemsPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Phone Systems</h3>
              </header>
              <p>
Phone systems have become the communcation backbone of many business, and their use has come a long way from just making phone calls. More and more business have integrated their phones, email clients, and Customer Relationship Management CRM) into a unified system giving them unparalleled access to all of their information. We provide the latest in VOIP and IP Phone technology and can help your business implement, upgrade, and maintain your phone systems.
                <br />
                <br />
We also provide help and support for traditional PBX systems, such as Terlda, NEC, Panasonic, and many others.

              </p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={erpPicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>CRM / ERP Integration</h3>
              </header>
              <p>
A business cannot succeed without a means to maintain and manage all of its internal data. Customer Relationship Management (CRM) and Enterprise Resource Planning (ERP) systems provide a way for businesses to track, review, and manage all internal transactions, and provide customers with updates on new and future business. These tools have helped thousands of busineesses track all their transactions and get work done on time.
                <br />
                <br />
                Ryecliff specializes in the implementation and management of these systems to help businesses reach their full potential. Call us today and see how you can take your business to the next step.

              </p>
            </div>
          </div>
        </section>
        <section>
          <img
            src={pointOfSalePicture}
            alt=""
            style={{
              width: 600,
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Point of Sale Systems</h3>
              </header>
              <p>
Point of Sale systems have helped business conduct transactions for many years, but who has helped these businesses implement and manage their POS systems?
                <br />
                <br />
We offer turnkey solutions for new POS implementations as well as serivce and managent of existing systems. Our team of experts can help your business keep running without spending a fortune.

              </p>
            </div>
          </div>
        </section>
      </section>
    </div>

  </Layout>
);

export default Solutions;
